import {
  getAppManifestFunc,
  openDashboard,
  openSettings,
  ACTIONS_ID,
} from './appManifest';
import { getTranslateFunction } from './i18n';

let sdk;
let _translate;

export const editorReady = async (
  _editorSDK,
  _appToken,
  { initialAppData },
) => {
  console.log('FAQ IN editorReady');
  sdk = _editorSDK;
  _translate = await getTranslateFunction(initialAppData.languageCode || 'en');
  sdk.addEventListener('appActionClicked', (event) => {
    console.log('FAQ editorReady', { event });
  });
};

export const getAppManifest = ({ appManifestBuilder }, _editorSDK) =>
  getAppManifestFunc({ appManifestBuilder }, _editorSDK, _translate);

export const handleAction = (args) => {
  const type = args.type;
  const payload = args.payload;
  try {
    console.log('FAQ handleAction', args);
    // switch (type) {
    //   case 'appInstalled':
    //     switch (payload.appDefinitionId) {
    //       default:
    //         return Promise.resolve();
    //     }
    //   default:
    //     // console.log(type, payload);
    //     return Promise.resolve();
    // }
  } catch (e) {
    // Promise.reject();
    throw e;
  }
};

